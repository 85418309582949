import React, { useEffect, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route } from "react-router-dom";
import RootContainer from "./RootContainer";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { configureStore } from "../store/configureStore";
import Cookies from "universal-cookie";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_URL } from "../api/api";

export default function AppContainer() {
  const store = useMemo(() => configureStore(), []);

  async function refreshTokenEachTime() {
    const cookies = new Cookies();
    let response = await axios.post(`${API_URL}token/refresh/`, {
      refresh: cookies.get("refresh_token"),
    });

    console.log(response);
    if (response.status === 200) {
      console.log("response", 200);
      cookies.set("access_token", response?.data?.access);
    }
  }

  useEffect(() => {
    const refreshTokenInterval = setInterval(() => {
      refreshTokenEachTime();
    }, 900000);

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, []);

  if (!store) {
    return null;
  }

  return (
    <Provider store={store.store}>
      <PersistGate persistor={store.persistor}>
        <BrowserRouter>
          <>
            <Route component={RootContainer} />
            <ToastContainer />
          </>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
