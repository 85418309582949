import React, { useEffect, useState } from "react";
import { api } from "../../../api/api";
import { API_VERSION } from "../../../api/api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "../../accepts/styles.css";
import ScrollableTable from "../components/Table";
import { TableCell, TableRow } from "@mui/material";

const MentorList = () => {
  const [resData, setResData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const columns = [
    { id: "name", label: "Ismi" },
    { id: "kasbi", label: "Kasbi" },
    { id: "email", label: "Email" }, //kasbi
    { id: "students", label: "O'quvchilar soni" }, // students
    { id: "phone", label: "Tel No" }, // students
  ];

  useEffect(() => {
    setLoading(true);
    let mounted = true;
    api.get(`${API_VERSION}/speakers-list`).then((res) => {
      if (mounted) {
        setLoading(false);
        //@ts-ignore
        setResData(res.results);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ScrollableTable columns={columns}>
          {resData?.map((row: any, idx: number) => (
            <TableRow key={idx}>
              {columns?.map((column, idx) => (
                <TableCell key={idx}>
                  {row[column.id] !== null ? row[column.id] : `nomalum`}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </ScrollableTable>
      )}
    </div>
  );
};

export default MentorList;
