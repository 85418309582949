import * as React from "react";

const SideBarImg3 = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-colorDisabled"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <path d="M20 6h-8l-1.41-1.41C10.21 4.21 9.7 4 9.17 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-1 12H5c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1z" />
  </svg>
);

export default SideBarImg3;
