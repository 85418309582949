import React, { useCallback, useEffect, useState } from "react";
// import "./assets/user.css";
import { useHistory } from "react-router";
import { api, API_VERSION } from "../../api/api";
import { priceFormat } from "../../constans/PriceFormater";
import SkeletonLoader from "../loader/SkeletonLoader";
import ReusableTable from "./components/Table";
import ScrollableTable from "./components/Table";
import { Box, CircularProgress, TableCell, TableRow } from "@mui/material";

export default function Users() {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [nextData, setNextData] = useState("");
  const columns = [
    { id: "name", label: "Ismi" },
    { id: "age", label: "Yoshi" },
    { id: "courses", label: "Kurslar soni" }, //
    { id: "total_balance", label: "Pul miqdori" }, //
    { id: "country", label: "Mamlakat" }, //
    { id: "job", label: "Kasbi" }, //
    { id: "phone", label: "Tel numeri" }, // phone
    { id: "total_bonus", label: "Jami bonus" }, // phone
  ];

  // console.log(data);

  const getNextData = useCallback(() => {
    api.get(`${nextData}`).then((res) => {
      //@ts-ignore
      setData((prev) => [...prev, ...res.results]);
      //@ts-ignore
      setNextData(res.next);
    });
  }, [nextData]);

  useEffect(() => {
    setLoading(true);
    let mounted = true;
    api.get(`${API_VERSION}/users-list`).then((res) => {
      if (mounted) {
        //@ts-ignore
        setData(res.results);
        setLoading(false);
        //@ts-ignore
        setNextData(res.next);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const history = useHistory();

  return (
    <div className="users-block">
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div>
            <ScrollableTable columns={columns}>
              {data?.map((row: any, idx: number) => (
                <TableRow key={idx}>
                  {columns?.map((column, idx) => (
                    <TableCell key={idx}>
                      {row[column.id] !== null
                        ? typeof row[column.id] === `string` ||
                          typeof row[column.id] === `number`
                          ? row[column.id]
                          : Object.keys(row[column.id]).length > 0 &&
                            row[column.id]?.name
                        : `nomalum`}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </ScrollableTable>
          </div>
          {!loading && (
            <div className="show-more-btn">
              <button onClick={getNextData}>Yana ko'rish</button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
