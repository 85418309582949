import * as React from "react";

const SideBarImg8 = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-colorDisabled"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
  </svg>
);

export default SideBarImg8;
