import React, {Dispatch, SetStateAction} from 'react';
import closeIcon from "./assets/close.svg";
import "./assets/courses.css";

interface Props {
    readonly fileUrl: string;
    readonly setFileUrl: Dispatch<SetStateAction<string>>;
}

function FileReader({fileUrl, setFileUrl}: Props) {
    return (
        <div className="file-reader">
            <div className="position-relative">
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                />
                <div className="hide-download"/>
                <div className="img-div" onClick={() => setFileUrl("")}>
                    <img src={closeIcon} alt=""/>
                </div>
                <div className="hide-content"/>
            </div>
        </div>
    );
}

export default FileReader;