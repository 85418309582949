export enum Routes {
  Main = "Main",
  Statistics = "/statistics",
  Speakers = "/speakers",
  SpeakerId = "/speaker",
  UsersId = "/user",
  Users = "/users",
  Courses = "/courses",
  CoursesId = "/course",
  Economics = "/economics",
  Accepts = "/accepts",
  Attech = "/attech",
  AcceptId = "/accept",
  Settings = "/settings",
  Quarantine = "/quarantine",
  Admins = "/admins",
  Login = "/login",
}
