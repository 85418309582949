// import React from "react";
// import {
//   Table,
//   TableContainer,
//   TableHead,
//   TableBody,
//   TableRow,
//   TableCell,
//   Paper,
// } from "@mui/material";

// function ReusableTable({ data, columns }) {
//   return (
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             {columns.map((column) => (
//               <TableCell key={column.id}>{column.label}</TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data?.map((row) => (
//             <TableRow key={row.id}>
//               {columns?.map((column) => (
//                 <TableCell key={column.id}>
//                   {row[column.id] === null
//                     ? "nomalum"
//                     : !!row[column.id]?.name === true
//                     ? row[column.id]?.name
//                     : row[column.id]}
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }

// export default ReusableTable;

import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const ScrollableTable = ({ columns, children }: any) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "75vh", minHeight: "40vh" }}
    >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column: any) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScrollableTable;
