import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CheckCircle } from "@mui/icons-material";

import "./styles.css";
import { NavLink } from "react-router-dom";
import { Routes } from "../../constans/Route";

function MyAccordion({ role, setOpen, children }) {
  return <Accordion>{children}</Accordion>;
}

export default MyAccordion;
