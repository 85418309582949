import { TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import ScrollableTable from '../components/Table';

const cookies = new Cookies();

interface IGroup {
	code: string;
	created_at: string;
	group: number;
	id: number;
	is_active: boolean;
	name: string;
	speaker: null;
	updated_at: string;
}

const Attech = () => {
	const [group, setGroup] = useState([]); // group
	const [isLoading, setIsLoading] = useState(true);

	const [speaker, setSpeaker] = useState([]); // speaker
	const [speakerIsLoading, setSpeakerLoading] = useState(true);
	console.log({ speaker });

	const [groupMontor, setGroupMentor] = useState({
		group: '',
		speaker: '',
	});

	const handleChange = (key: string, value: string) => {
		setGroupMentor(prevValue => ({ ...prevValue, [key]: value }));
	};

	const handleSubmit = async () => {
		try {
			const response = await fetch(`https://inpuls-backend.backoffice.uz/api-web/attach_group_to_speaker/`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + cookies.get('access_token'),
				},
				body: JSON.stringify(groupMontor),
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			toast.success('Maʼlumotlar muvaffaqiyatli topshirildi', {
				position: toast.POSITION.TOP_RIGHT,
			});
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const groupUrl = `https://inpuls-backend.backoffice.uz/group/`;
	const speakerUrl = `https://inpuls-backend.backoffice.uz/backoffice/speakers-list`;

	const fetchData = async (url: string, saveData: any, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
		try {
			const response = await fetch(url, {
				headers: {
					Authorization: 'Bearer ' + cookies.get('access_token'),
				},
			});
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			const jsonData = await response.json();

			console.log({});
			console.log({ jsonData });

			saveData(jsonData);
			setIsLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData(groupUrl, setGroup, setIsLoading);
		fetchData(speakerUrl, setSpeaker, setSpeakerLoading);
	}, []);

	const columns = [
		{ id: 'name', label: 'Gruppa Nomi', isMentor: false },
		{ id: 'first_name', label: 'Briktirilgan Mentor', isMentor: true },
	];

	return (
		<div
			className='accept-block'
			style={{
				padding: '3rem 3rem 9rem 3rem',
			}}
		>
			<h4 style={{ fontWeight: 'bold' }}>Mentorga guruhni biriktiring</h4>
			<section
				style={{
					minWidth: '60%',
					minHeight: '100px',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div>
					<label htmlFor='group'>Guruh tanlang:</label> <br />
					<select
						style={{
							fontSize: '16px',
							border: '1px solid #ccc',
						}}
						name='group'
						id='group'
						onChange={e => handleChange(e.target.name, e.target.value)}
					>
						<option value='option2' selected disabled>
							Guruh tanlang
						</option>
						{group?.map(({ id, name }) => {
							return (
								<option key={id} value={id}>
									{name}
								</option>
							);
						})}
					</select>
				</div>
				<div>
					<label htmlFor='speaker'>Mentorni tanlang:</label> <br />
					<select
						style={{
							fontSize: '16px',
							border: '1px solid #ccc',
						}}
						name='speaker'
						id='speaker'
						onChange={e => handleChange(e.target.name, e.target.value)}
					>
						<option value='option1' selected disabled>
							Mentorni tanlang
						</option>
						{speaker?.map(({ id, name }) => {
							return (
								<option key={id} value={id}>
									{name}
								</option>
							);
						})}
					</select>
				</div>
			</section>
			<div className='show-more-btn'>
				<button onClick={handleSubmit}>Saqlash</button>
			</div>

			<ScrollableTable columns={columns}>
				{group?.map((row: any, idx) => (
					<TableRow key={idx}>
						{columns?.map(column => (
							<TableCell key={column.id}>
								{row[column.id] === null ? 'nomalum' : row[column.id]}

								{!!row[`speaker`]?.speaker[column.id] && column.isMentor
									? row[`speaker`]?.speaker[column.id]
									: !row[`speaker`]?.speaker[column.id] && column.isMentor && `nomalum`}
							</TableCell>
						))}
					</TableRow>
				))}
			</ScrollableTable>
			{/* <HoverTable data={group} /> */}
		</div>
	);
};

export default Attech;
